<template>
  <button
    v-if="props.type !== 'float'"
    type="button"
    :class="['btn_consult', 'type_static', { type_banner: props.type === 'banner' }]"
    :data-tiara-action-name="`${props.logInfo.name}_도입문의_클릭`"
    data-tiara-action-kind="ClickContent"
    :data-tiara-layer="`consult_button ${props.logInfo.page}_consult_button`"
    @click="openLayer"
  >
    도입문의
  </button>
  <div
    v-else
    class="wrap_float"
  >
    <button
      type="button"
      class="btn_consult"
      @click="openLayer"
    >
      <span class="screen_out">도입문의</span>
    </button>
    <div
      v-if="!consultInfo.isEnd"
      class="box_info"
    >
      <span
        >서비스에 관심이 있으시다면<br />
        <em>도입문의</em> 하러가기</span
      >
      <button
        type="button"
        class="btn_close"
        @click.stop.prevent="consultInfo.setConsultEnd"
      >
        <span class="screen_out">닫기</span>
      </button>
    </div>
  </div>
</template>
<script setup>
const emit = defineEmits(['onBeforeOpen']);
import { useConsultOpen, useBtnConsultStore } from '/stores/store';
const consultLayer = useConsultOpen();
const consultInfo = useBtnConsultStore();

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  logInfo: {
    type: Object,
    default() {
      return {};
    },
  },
});
const openLayer = () => {
  emit('onBeforeOpen');
  consultLayer.toggleConsultLayer('open');
};
</script>
<style lang="scss" scoped>
.btn_consult.type_static {
  display: inline-block;
  height: 38px;
  border-radius: 19px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  background-color: #1a1a1a;
  color: #fff;
  &.type_banner {
    font-size: 19px;
    font-weight: 700;
    height: 64px;
    padding: 0 39px;
    line-height: 64px;
    border-radius: 12px;
  }
}
@media screen and (max-width: 1023px) {
  .btn_consult.type_static {
    &.type_banner {
      font-size: 14px;
      font-weight: 900;
      height: 44px;
      padding: 0 53px;
      line-height: 44px;
      border-radius: 8px;
    }
  }
  .wrap_float {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    .box_info {
      box-sizing: border-box;
      position: absolute;
      right: 0;
      bottom: 64px;
      padding: 12px 35px 12px 12px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
      font-size: 14px;
      letter-spacing: -0.5px;
      @include setSize(203px, 64px);
      em {
        font-weight: 700;
      }
    }
    .btn_close {
      position: absolute;
      right: 12px;
      top: 12px;
      @include setSize(16px, 16px);
      @include closeBtnIcon();
      background-size: cover;
    }
    .btn_consult {
      background-color: #1a1a1a;
      @include setSize(56px, 56px);
      border-radius: 28px;
      @include backgroundImage(
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' fill='none'%3E%3Cpath d='M26.999 23.147c2.86-4.8 2.226-11.104-1.905-15.236-4.883-4.882-12.798-4.881-17.68 0-4.881 4.882-4.882 12.797 0 17.68 4.132 4.131 10.437 4.766 15.236 1.905l4.193 1.431c.983.336 1.923-.604 1.587-1.587L27 23.148z' fill='%23fff'/%3E%3Cpath d='M14.969 19.352c.008-2.147.586-2.803 1.62-3.45.742-.482 1.32-1.017 1.312-1.81.008-.863-.665-1.423-1.501-1.432-.802.009-1.552.543-1.587 1.552h-2.449c.043-2.423 1.846-3.587 4.053-3.587 2.406 0 4.105 1.25 4.105 3.38 0 1.423-.733 2.32-1.863 3.001-.974.578-1.405 1.139-1.414 2.346v.19h-2.277v-.19zm-.26 2.639c-.016-.794.639-1.44 1.45-1.432.767-.009 1.431.638 1.431 1.432 0 .802-.664 1.448-1.431 1.448-.811 0-1.466-.646-1.45-1.448z' fill='%231A1A1A'/%3E%3C/svg%3E",
        34px 34px,
        center
      );
    }
  }
}
</style>
